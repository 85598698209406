/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Post, PostState, FetchPostsResponse } from 'ui/types/postTypes';
import { ErrorPayload } from 'ui/types';
import { fetchPosts, fetchPost } from 'ui/store/actions/postActions';

const initialState: PostState = {
  posts: [],
  post: null,
  selectedPost: null,
  status: 'idle',
  error: null,
  totalResults: 0,
  totalPages: 0,
};

const postSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.fulfilled, (state, action: PayloadAction<FetchPostsResponse>) => {
        state.status = 'succeeded';
        state.posts = action.payload.posts;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchPosts.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'Failed to fetch posts';
      })
      .addCase(fetchPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPost.fulfilled, (state, action: PayloadAction<Post>) => {
        state.status = 'succeeded';
        state.selectedPost = action.payload;
      })
      .addCase(fetchPost.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'Failed to fetch post';
      });
  },
});

export default postSlice.reducer;
