/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment */

type State = { [key: string]: any };

export type Action =
  | { type: 'SET_STATE'; payload: Partial<State> }
  | { type: 'SET_KEY'; key: string; payload: any }
  | { type: 'SET_SUBKEY'; key: string; subkey: string; payload: any };

export const actionTypes = {
  SET_STATE: 'SET_STATE',
  SET_KEY: 'SET_KEY',
  SET_SUBKEY: 'SET_SUBKEY',
} as const;

export const GenericReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case actionTypes.SET_STATE:
      return {
        ...state,
        ...action.payload,
      };
    case actionTypes.SET_KEY:
      return {
        ...state,
        [action.key]: action.payload,
      };
    case actionTypes.SET_SUBKEY:
      return {
        ...state,
        [action.key]: { ...state[action.key], [action.subkey]: action.payload },
      };
    default:
      return state;
  }
};

export default GenericReducer;
