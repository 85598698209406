/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { titleize } from 'inflection';
import { Link } from 'ui/components/Common';
import { CoachingEnrollmentChallengeItem } from 'ui/types';

interface Props {
  item: CoachingEnrollmentChallengeItem;
}

const statusClass = (status: string): string => {
  if (['program_ended', 'registration_ended', 'incomplete'].includes(status)) { return 'text-red-500'; }
  if (status === 'closing_soon') { return 'text-yellow-500'; }
  if (status === 'complete') { return 'text-green-500'; }
  return 'text-gray-500';
};

const statusText = (status: string): string => {
  if (['rejoinable', 'registration_open'].includes(status)) { return 'Joinable'; }
  if (['program_ended', 'registration_ended'].includes(status)) { return 'Closed'; }
  if (status === 'future') { return 'Not Yet Open'; }
  return titleize(status);
};

export const Coaching: React.FC<Props> = ({ item }) => {
  const programs = item.coachingPrograms;

  return (
    <div className="mt-2 flex flex-col gap-3">
      {programs.map(program => (
        <div key={program.id} className="flex flex-col md:flex-row border border-mid-gray rounded-lg">
          <div className="text-center md:text-start md:pl-3 w-full">{program.name}</div>
          <div className={`w-full ${statusClass(program.status)}`}>{statusText(program.status)}</div>
          <div className="text-center md:text-end md:pr-3 w-full"><Link to={`/coaching/programs/${program.id}`}>View Program</Link></div>
        </div>
      ))}

      <Link
        to="/coaching"
        className="mt-2 mx-auto btn btn-primary"
      >
        View Programs
      </Link>
    </div>
  );
};

export default Coaching;
