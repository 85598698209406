import { createAsyncThunk } from '@reduxjs/toolkit';
import { Client, Dashboard, ErrorPayload } from 'ui/types';
import { apiClient, getErrorMessage } from 'ui/utils';

export const fetchClient = createAsyncThunk<Client, void, { rejectValue: ErrorPayload }>(
  'client/fetchClient',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<Client>('/client?detailed=true');
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);

export const fetchDashboard = createAsyncThunk<Dashboard, void, { rejectValue: ErrorPayload }>(
  'client/fetchDashboard',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<Dashboard>('/dashboard/modular');
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);
