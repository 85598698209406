import React from 'react';
import { Link } from 'ui/components/Common';

interface FooterItemProps {
  text: string;
  path?: string;
}

export const FooterItem: React.FC<FooterItemProps> = ({
  text, path = undefined,
}) => (
  <div className="my-1">
    {!path && <div aria-label={text} className="text-secondary">{text}</div>}
    {path && <Link to={path} label={text} className="text-secondary">{text}</Link>}
  </div>
);

export default FooterItem;
