import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectFeatures } from 'ui/store/selectors';
import { hasFeature } from 'ui/utils';

const useLayout = (): { layout: string, login: boolean, root: boolean } => {
  const features = useSelector(selectFeatures);
  const splash = hasFeature('splash', features);

  const result = useMemo(() => {
    const root = window.location.pathname === '/';
    const login = window.location.pathname === '/users/sign_in';
    const layout = (root || (login && splash)) ? 'full' : 'page';
    return { root, login, layout };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
};

export default useLayout;
