import { createAsyncThunk } from '@reduxjs/toolkit';
import { User, ErrorPayload } from 'ui/types';
import { apiClient, getErrorMessage } from 'ui/utils';

const fetchUser = createAsyncThunk<User, void, { rejectValue: ErrorPayload }>(
  'user/fetchUser',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<User>('/user?detailed=true');
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);

export default fetchUser;
