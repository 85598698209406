/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectSplash } from 'ui/store/selectors';
import { NavItem } from '../NavItem';
import useNavigation from '../useNavigation';

interface HeaderProps {
  layout: string;
}

export const Bar: React.FC<HeaderProps> = ({ layout }) => {
  const splash = useSelector(selectSplash);
  const navItems = useNavigation();

  if (!splash) { return null; }

  return (
    <div className="mx-auto my-2 hidden md:block" data-testid="splash-nav-bar">
      <nav className="flex gap-4 lg:gap-8 items-center">
        <NavItem layout={layout} item={navItems.splash.overview} />

        {splash.posts.length > 0 && (
          <NavItem layout={layout} item={navItems.splash.about} />
        )}

        {splash.sponsorContent && (
          <NavItem layout={layout} item={navItems.splash.sponsors} />
        )}

        <NavItem layout={layout} item={navItems.splash.contact} />
      </nav>
    </div>
  );
};

export default Bar;
