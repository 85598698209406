import { RootState } from '..';

export const selectUser = (state: RootState) => state.user.user;
export const selectImpersonator = (state: RootState) => state.user.user?.impersonator;
export const selectUserFullName = (state: RootState) => `${state.user.user?.firstName} ${state.user.user?.lastName}`;
export const selectUserFirstName = (state: RootState) => state.user.user?.firstName;
export const selectUserLastName = (state: RootState) => state.user.user?.lastName;
export const selectUserPhoto = (state: RootState) => state.user.user?.profileImage;
export const selectUserPlanDesign = (state: RootState) => state.user.user?.planDesign;
export const selectUserNextActivity = (state: RootState) => state.user.user?.planDesign?.nextActivity;
export const selectUserId = (state: RootState) => state.user.user?.id;
export const selectUserMessageCount = (state: RootState) => state.user.user?.unreadMessages;
export const selectUserRecommended = (state: RootState) => state.user.user?.recommended;
