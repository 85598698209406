import { createAsyncThunk } from '@reduxjs/toolkit';
import { Post } from 'ui/types/postTypes';
import { ErrorPayload } from 'ui/types';
import { apiClient, getErrorMessage } from 'ui/utils';

interface FetchPostsParams {
  page?: number;
  per_page?: number;
  offset?: number;
  type?: string;
  search?: string;
  mobile?: boolean;
  favorites?: boolean;
  recommended?: boolean;
  hub_posts?: boolean;
  difficulty?: string;
  time?: string;
  selectedTags?: string;
}

interface FetchPostsResponse {
  posts: Post[];
  totalResults: number;
  totalPages: number;
}

export const fetchPosts = createAsyncThunk<FetchPostsResponse, FetchPostsParams, { rejectValue: ErrorPayload }>(
  'posts/fetchPosts',
  async (params, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<Post[]>(`/posts?${new URLSearchParams(params as URLSearchParams).toString()}`);
      const totalResults = parseInt(response.headers['x-total'] as string, 10);
      const totalPages = parseInt(response.headers['x-total-pages'] as string, 10);
      return { posts: response.data, totalResults, totalPages };
    } catch (error) {
      return rejectWithValue({ message: getErrorMessage(error) });
    }
  },
);

export const fetchTags = createAsyncThunk<string[], void, { rejectValue: ErrorPayload }>(
  'tags/fetchTags',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<string[]>('/posts/tags?page=1&per_page=25&offset=0&type=Recipe');
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);

export const fetchPost = createAsyncThunk<Post, string, { rejectValue: ErrorPayload }>(
  'posts/fetchPost',
  async (slug: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<Post>(`/posts/${slug}`);
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);
