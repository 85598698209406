import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser, selectClientName } from 'ui/store/selectors';
import { Link } from 'ui/components/Common';
import { hasPermission } from 'ui/utils';
import { Times } from 'ui/components/Icons';
import getEnvironment from 'packs/common/getEnvironment';

export const Admin: React.FC = () => {
  const user = useSelector(selectUser);
  const clientName = useSelector(selectClientName);
  if (!user) { return null; }

  let bgColor: string;
  const environment = getEnvironment();

  switch (environment) {
    case 'beta':
      bgColor = 'bg-[#CB1D3E]';
      break;
    case 'staging':
      bgColor = 'bg-[#F0AD4E]';
      break;
    case 'production':
      bgColor = 'bg-[#2D2D2D]';
      break;
    default:
      bgColor = 'bg-[#007BFF]';
      break;
  }

  const remove = () => {
    document.querySelector('#admin-nav-bar')?.remove();
  };

  return (
    <div
      id="admin-nav-bar"
      className={`${bgColor} flex flex-col md:flex-row items-center justify-between gap-y-5 px-5 py-3 w-full text-center text-white`}
      data-testid="admin-nav-bar"
    >
      <div className="flex items-center gap-5">
        <span className="uppercase text-title">Admin</span>

        {environment !== 'production' && (
          <span className="uppercase text-xs btn btn-sm bg-transparent border-white cursor-default">{environment}</span>
        )}
      </div>

      {user.impersonator && (
        <div data-testid="impersonation" className="flex flex-col gap-y-2 sm:gap-y-0">
          <div className="flex flex-col sm:flex-row justify-center items-center gap-x-2">
            <div>You are impersonating {user.firstName} {user.lastName}.</div>

            <Link
              to="/users/unbecome"
              className="uppercase text-xs btn btn-sm bg-transparent border-white"
            >
              Stop Impersonating
            </Link>
          </div>

          <div className="flex flex-col sm:flex-row justify-center gap-x-3">
            <div className="flex justify-center gap-x-3">
              <div>ID: <span className="font-bold">{user.id}</span></div>
              <div>Role: <span className="font-bold">{user.access.role}</span></div>
              <div>Active: <span className="font-bold">{user.access.active ? 'Yes' : 'No'}</span></div>
            </div>

            <div>Client: <span className="font-bold">{clientName}</span></div>
          </div>
        </div>
      )}

      <div className="flex items-center gap-5">
        {hasPermission('developer', user.access) && <Link to="/sidekiq">Sidekiq</Link>}

        <Link
          to={hasPermission('admin', user.access) ? '/admin' : '/users/unbecome'}
        >
          Go to Admin
        </Link>

        <div
          role="button"
          tabIndex={0}
          className="flex items-center hover:cursor-pointer"
          data-testid="remove-nav"
          onClick={remove}
          onKeyDown={e => e.key === 'Enter' && remove()}
        >
          <Times width="w-4" height="h-4" stroke="stroke-white" />
        </div>
      </div>
    </div>
  );
};

export default Admin;
