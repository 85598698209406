import { configureStore } from '@reduxjs/toolkit';
import { camelizeKeys } from 'ui/utils/camelizeKeys';
import { clientReducer, userReducer, postReducer, tagReducer, dashboardReducer, todosReducer } from './reducers';

export type RootState = {
  client: ReturnType<typeof clientReducer>;
  dashboard: ReturnType<typeof dashboardReducer>;
  user: ReturnType<typeof userReducer>;
  posts: ReturnType<typeof postReducer>;
  tags: ReturnType<typeof tagReducer>;
  todos: ReturnType<typeof todosReducer>;

};

const store = (props: { client: unknown, user: unknown, dashboard?: unknown, todos: unknown }) => (
  configureStore({
    reducer: {
      client: clientReducer,
      dashboard: dashboardReducer,
      user: userReducer,
      posts: postReducer,
      tags: tagReducer,
      todos: todosReducer,
    },
    preloadedState: {
      client: {
        client: camelizeKeys(props.client),
        status: 'idle',
        error: null,
      },
      user: {
        user: camelizeKeys(props.user),
        status: 'idle',
        error: null,
      },
      dashboard: {
        config: camelizeKeys(props.dashboard),
        data: {},
        status: 'idle',
        error: null,
      },
      todos: {
        todos: camelizeKeys(props.todos),
        status: 'idle',
        error: null,
      },
    },
  })
);

export type AppDispatch = ReturnType<typeof store>['dispatch'];

export default store;
