import { ChallengeItemType } from 'ui/types/commonTypes';

export enum Category {
  Balance = 'balance',
  Community = 'community',
  Financial = 'financial',
  Mindfulness = 'mindfulness',
  Nutrition = 'nutrition',
  Physical = 'physical',
  Purpose = 'purpose',
  Social = 'social',
  Other = 'other',
}

export interface ChallengeUser {
  completed: boolean;
  rejoinable: boolean;
  timeline: { totalDays: number, daysInto: number, percentage: number };
}

export interface ChallengeItem {
  id: number;
  name: string;
  longName: string;
  type: ChallengeItemType;
  category: string;
  frequency: string;
  unit: string;
  unitType: string;
  simpleRecording: boolean;
}

export interface Challenge {
  id: number;
  name: string;
  sanitizedDescription: string;
  link: string;
  image: string | null;
  category: string;
  featured: boolean;
  duration: number;
  startDate?: string;
  endDate?: string;
  registerStartDate: string;
  registerEndDate: string;
  enrollable?: boolean;
  items: ChallengeItem[];
  enrollment?: ChallengeUser;
  invited?: boolean;
}

export interface Course extends Challenge {}

export interface Todo {
  id: number;
  challengeItemId: number;
  challengeId: number;
  title: string;
  challengeTitle: string;
  category: string;
  itemType: ChallengeItemType;
  type: string;
  deviceOnly: boolean;
  completed: boolean;
  startDate: string;
  endDate: string;
  cutoff: string;
}

export interface TodoState {
  todos: Todo[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}
