import React from 'react';

interface ToDosButtonProps {
  count: number;
  layout: string;
  onClick: () => void;
}

export const Button: React.FC<ToDosButtonProps> = ({ count, layout, onClick }) => {
  const countColors = layout === 'full' ? 'text-black bg-white' : 'bg-black text-white';
  const borderColors = layout === 'full' ? 'border-white' : 'border-black';
  const textColors = layout === 'full' ? 'text-white' : 'text-black';

  return (
    <button
      onClick={onClick}
      type="button"
      aria-label="Todos Button"
      className={`flex items-center gap-2.5 rounded-3xl border-2 border-solid py-[6px] pr-[8px] pl-[8px] cursor-pointer ${borderColors}`}
    >
      <div className={`whitespace-nowrap my-auto text-[14px] ${textColors}`}>To-Dos</div>
      <div className={`whitespace-nowrap rounded-full h-[25px] w-[25px] ${countColors}`}>
        {count}
      </div>
    </button>
  );
};

export default Button;
