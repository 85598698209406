/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { GenericReducer } from './reducers/GenericReducer';

type StoreProps = {
  children: ReactNode;
  initialState: { [key: string]: any };
};

const StateContext = createContext<any>({});
const DispatchContext = createContext<any>(GenericReducer);

export const Store: React.FC<StoreProps> = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(GenericReducer, initialState);

  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        {children}
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export function useStore<State, Action>() {
  const state = useContext(StateContext) as State;
  const dispatch = useContext(DispatchContext) as React.Dispatch<Action>;

  if (state === undefined || dispatch === undefined) {
    throw new Error('useStore must be used within a Store');
  }

  return { state, dispatch };
}
