import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { useStore } from 'ui/store/GenericContext';
import { ReactSelectOption, Todo as TodoType } from 'ui/types';
import { apiClient, fitnesses } from 'ui/utils';
import { formatOption, overrideControl, overrideThemes, overrideComponents } from 'ui/utils/reactSelect';

interface State {
  name: ReactSelectOption | null;
  intensity: ReactSelectOption | null;
  duration: string;
}

const initialState: State = {
  name: null,
  intensity: null,
  duration: '',
};

export const Fitness: React.FC<{ todo: TodoType }> = ({ todo }) => {
  const { dispatch } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<State>(initialState);

  const submit = () => {
    const params = {
      name: data.name?.value,
      intensity: data.intensity?.value,
      duration: parseInt(data.duration, 10) * 60,
    };

    setSubmitting(true);

    apiClient.post('fitnesses', params)
      .then(() => {
        setData(initialState);
        setSuccess(true);
        dispatch({ type: 'SET_KEY', key: 'update', payload: todo.itemType });
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (error || success) {
      timeout = setTimeout(() => {
        setError(false);
        setSuccess(false);
        setSubmitting(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, success]);

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="w-full md:w-3/5 flex flex-col md:flex-row justify-center gap-x-2 gap-y-4">
        <div className="form-group w-full">
          <label id="type-label" htmlFor="type">Activity Type</label>

          <Select
            name="type"
            id="type"
            aria-labelledby="type-label"
            value={data.name}
            className="form-control input-3"
            options={fitnesses.map(formatOption)}
            onChange={(e: ReactSelectOption) => setData({ ...data, name: e })}
            styles={{ control: base => (overrideControl(base)) }}
            theme={theme => overrideThemes(theme)}
            components={overrideComponents()}
          />
        </div>

        <div className="form-group w-full">
          <label id="intensity-label" htmlFor="intensity">Intensity</label>

          <Select
            name="intensity"
            id="intensity"
            aria-labelledby="intensity-label"
            value={data.intensity}
            className="form-control input-3"
            options={['Low', 'Medium', 'High'].map(formatOption)}
            onChange={(e: ReactSelectOption) => setData({ ...data, intensity: e })}
            styles={{ control: base => (overrideControl(base)) }}
            theme={theme => overrideThemes(theme)}
            components={overrideComponents()}
          />
        </div>

        <div className="form-group w-full">
          <label id="duration-label" htmlFor="duration">Duration</label>

          <input
            name="duration"
            id="duration"
            required
            type="number"
            min="0"
            max="1440"
            className="form-control !py-2.5"
            placeholder="Minutes"
            value={data.duration}
            onChange={e => setData({ ...data, duration: e.target.value })}
          />
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-y-2">
        <button
          type="button"
          className={classNames('btn btn-primary w-3/5 md:w-1/5', { 'btn-disabled': submitting })}
          disabled={submitting || !(data.name && data.intensity && data.duration)}
          onClick={submit}
        >
          Submit
        </button>

        {error && (
          <span className="text-danger-default text-accent">
            There was an error submitting your activity. Please try again later.
          </span>
        )}

        {success && (
          <span className="text-success-default text-accent">
            Your activity has been recorded!
          </span>
        )}
      </div>
    </div>
  );
};

export default Fitness;
