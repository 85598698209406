import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUser } from 'ui/store/actions';
import { RootState, AppDispatch } from 'ui/store';

interface UserContextType {
  user: RootState['user'];
  refreshUser: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: RootState) => state.user);

  const refreshUser = async () => {
    try {
      await dispatch(fetchUser()).unwrap();
    } catch (error) {
      console.error('Failed to refresh user:', error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ user, refreshUser }), [user]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
