import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { apiClient } from 'ui/utils';
import { Todo } from 'ui/types';

interface Props {
  todo: Todo;
}

export const Simple: React.FC<Props> = ({ todo }) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const submit = () => {
    setSubmitting(true);

    apiClient.post(`challenges/${todo.challengeId}/todos/${todo.id}/record`)
      .then(() => setSuccess(true))
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (error || success) {
      timeout = setTimeout(() => {
        setError(false);
        setSuccess(false);
        setSubmitting(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, success]);

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="w-full flex flex-col items-center gap-y-2">
        <button
          type="button"
          className={classNames('btn btn-primary w-3/5 md:w-1/5', { 'btn-disabled': submitting })}
          disabled={submitting}
          onClick={submit}
        >
          I Did This
        </button>

        {error && (
          <span className="text-danger-default text-accent">
            There was an error submitting your activity. Please try again later.
          </span>
        )}

        {success && (
          <span className="text-success-default text-accent">
            Your activity has been recorded!
          </span>
        )}
      </div>
    </div>
  );
};

export default Simple;
