/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClientState, Client } from 'ui/types/clientTypes';
import { ErrorPayload } from 'ui/types';
import { fetchClient } from 'ui/store/actions/clientActions';

export const initialState: ClientState = {
  client: null,
  status: 'idle',
  error: null,
};

const clientReducer = createSlice({
  name: 'client',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClient.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClient.fulfilled, (state, action: PayloadAction<Client>) => {
        state.status = 'succeeded';
        state.client = action.payload;
      })
      .addCase(fetchClient.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message ?? 'Unknown error';
      });
  },
});

export default clientReducer.reducer;
