import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectClient, selectUser } from 'ui/store/selectors';
import { Store } from 'ui/store/GenericContext';
import { Link } from 'ui/components/Common';
import { hasFeature, hasPermission } from 'ui/utils';
import { Admin } from './Admin';
import { Bar as SplashBar } from './Splash/Bar';
import { Meta as SplashMeta } from './Splash/Meta';
import { Bar as StandardBar } from './Standard/Bar';
import { Meta as StandardMeta } from './Standard/Meta';
import { Drawer as TodosDrawer, Modal as TodosModal } from './Standard/Todos';
import useLayout from './useLayout';
import { todosState } from './Standard/Todos/types';

interface Props {
  restricted?: boolean;
}

export const Header: React.FC<Props> = ({ restricted = false }) => {
  const [showTodos, setShowTodos] = useState(false);
  const { layout, login } = useLayout();
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);
  const features = client?.featureSettings;
  const splash = client?.splash;

  if (!client) { return null; }

  const headerClasses = (): string => {
    const base: string[] = [];

    if (restricted) {
      if (!login) { base.push('justify-center'); }
      if (login && layout === 'full') { base.push('justify-between'); }
      if (login && layout === 'page') { base.push('justify-center'); }
    } else {
      base.push('justify-between');
    }

    return base.join(' ');
  };

  const renderLogo = () => {
    let justification = restricted ? 'justify-center' : 'justify-start';
    if (login && layout === 'full') { justification = 'justify-start'; }
    if (login && layout === 'page') { justification = 'justify-center'; }

    return (
      <div className={`flex my-1 md:w-[20%] ${justification}`}>
        <Link to="/" label="Navigate to Home" className="mr-4 cursor-pointer hidden min-[320px]:block">
          <img
            loading="lazy"
            src={client?.theme?.darkLogo}
            alt={client?.name}
            className="h-[36px] object-scale-down"
          />
        </Link>
      </div>
    );
  };

  const standardHeader = () => (
    <React.Fragment>
      <StandardBar layout={layout} />
      <StandardMeta layout={layout} todosState={{ showTodos, setShowTodos }} />
    </React.Fragment>
  );

  const splashHeader = () => {
    if (!splash) { return null; }

    return (
      <React.Fragment>
        <SplashBar layout={layout} />
        <SplashMeta />
      </React.Fragment>
    );
  };

  return (
    <div className="flex flex-col items-start no-print">
      {user && !restricted && (hasPermission('admin', user.access) || user.impersonator) && <Admin />}

      <header className={`w-full px-[26px] pt-[17px] ${layout === 'full' ? 'bg-transparent' : 'bg-white h-[76px]'}`}>
        <div className={`max-w-screen-lg mx-auto flex flex-row ${headerClasses()}`}>
          {renderLogo()}

          {login && hasFeature('splash', features) && splashHeader()}
          {!restricted && standardHeader()}
        </div>
      </header>

      {!!user && (
        <div className={`w-full ${showTodos ? 'block' : 'invisible'}`}>
          <Store initialState={todosState}>
            <TodosDrawer layout={layout} setShowTodos={setShowTodos} />
            <TodosModal />
          </Store>
        </div>
      )}
    </div>
  );
};

export default Header;
