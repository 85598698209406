import React from 'react';
import { Link } from 'ui/components/Common';

type NavSubItemProps = {
  label: string;
  path: string;
  method?: string | undefined;
};

export const NavSubItem: React.FC<NavSubItemProps> = ({ label, path, method }) => (
  <Link
    to={path}
    method={method}
    label={label}
    role="menuitem"
    tabIndex={-1}
    className="block w-full text-left px-4 py-2 text-black hover:bg-light-gray cursor-pointer"
  >
    {label}
  </Link>
);

export default NavSubItem;
