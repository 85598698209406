import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectUser, selectFeatures } from 'ui/store/selectors';
import { ImageThumbnail } from 'ui/components/Common';
import { hasFeature, hasPermission } from 'ui/utils';
import { NavSubItem } from '../NavSubItem';
import useNavigation from '../useNavigation';

export const Account: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const features = useSelector(selectFeatures);
  const user = useSelector(selectUser);
  const navItems = useNavigation();
  if (!user) { return null; }

  const renderDivider = () => (
    <hr className="shrink-0 border border-solid bg-stone-300 border-stone-300 mx-4" />
  );

  return (
    <div
      className="hidden md:block relative"
      role="menubar"
      aria-label="Account Menu"
      tabIndex={0}
      onMouseEnter={() => setMenuOpen(true)}
      onMouseLeave={() => setMenuOpen(false)}
      onClick={() => setMenuOpen(!menuOpen)}
      onKeyDown={e => e.key === 'Enter' && setMenuOpen(!menuOpen)}
    >
      <ImageThumbnail
        src={user.profileImage}
        alt="Profile Photo"
        className="min-w-[45px] w-[45px] aspect-square rounded-full bg-light-gray"
      />

      <div className={classNames('z-20 absolute w-48 bg-transparent right-0', { hidden: !menuOpen })}>
        <div className="mt-2 top-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg z-50">
          <NavSubItem {...navItems.account.publicProfile} />

          {hasFeature('messaging', features) && (
            <NavSubItem {...navItems.account.messageCenter} />
          )}

          {renderDivider()}

          <NavSubItem {...navItems.account.accountSettings} />
          <NavSubItem {...navItems.account.privacySettings} />
          <NavSubItem {...navItems.account.notificationSettings} />

          {renderDivider()}

          {hasFeature('translation', features) && (
            <NavSubItem {...navItems.account.language} />
          )}
          <NavSubItem {...navItems.account.support} />

          {hasPermission('admin', user.access) && <NavSubItem {...navItems.account.admin} />}
          {hasPermission('coaching', user.access) && <NavSubItem {...navItems.account.coaching} />}

          {renderDivider()}

          <NavSubItem {...navItems.account.signout} />
        </div>
      </div>
    </div>
  );
};

export default Account;
