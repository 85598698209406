import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserMessageCount } from 'ui/store/selectors';
import { Link } from 'ui/components/Common';
import { Bell } from 'ui/components/Icons';

interface Props {
  layout: string;
}

export const Messages: React.FC<Props> = ({ layout }) => {
  const count = useSelector(selectUserMessageCount) || 0;
  const stroke = layout === 'full' ? 'stroke-white' : 'stroke-dark-gray';

  return (
    <div className="hidden md:block relative h-5 mx-2">
      <Link to="/messages" label="Messages">
        <Bell width="w-[25px]" height="h-[25px]" stroke={stroke} />

        {count > 0 && (
          <div className="absolute rounded-lg top-[-10px] right-[-10px] inline-flex items-center justify-center bg-red-700">
            <span id="unread-message-count" className="px-1 text-[12px] text-white font-bold">{count}</span>
          </div>
        )}
      </Link>
    </div>
  );
};

export default Messages;
