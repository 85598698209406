/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useState } from 'react';
import classNames from 'classnames';
import { Link } from 'ui/components/Common';
import { CaratDown } from 'ui/components/Icons';

type NavItemProps = {
  layout: string;
  name?: string;
  item: {
    label: string;
    path?: string;
  };
  children?: React.ReactNode;
};

export const NavItem: React.FC<NavItemProps> = ({
  layout, name = 'item', item, children = undefined,
}) => {
  const [active, setActive] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const textColor = layout === 'full' ? 'text-white' : 'text-stone-900';
  const fillColor = layout === 'full' ? 'fill-white' : 'fill-stone-900';
  const borderColor = layout === 'full' ? 'border-b-white' : 'border-b-stone-900';
  const hasDropdown = !!children;

  const toggleActive = (state: boolean) => {
    setActive(state);
    setMenuOpen(state);
  };

  return (
    <div
      className="relative"
      role="menubar"
      aria-label={item.label}
      tabIndex={0}
      onMouseEnter={() => toggleActive(true)}
      onMouseLeave={() => toggleActive(false)}
      onClick={() => toggleActive(!active)}
    >
      <Link
        to={item.path || ''}
        label={item.label}
        role="menu"
        tabIndex={0}
        className={`flex gap-1 justify-center cursor-pointer border-y-2 border-transparent ${active ? `border-solid ${borderColor}` : ''}`}
      >
        <div className={`text-base leading-6 ${textColor}`}>{item.label}</div>

        {hasDropdown && (
          <div className="flex justify-center items-center py-0.5 my-auto" data-testid="dropdown-carat">
            <CaratDown width="w-[9px]" height="h-[8px]" fill={fillColor} />
          </div>
        )}
      </Link>

      {hasDropdown && (
        <div data-testid={`${name}-dropdown`} className={classNames('z-20 absolute w-48 bg-transparent', { hidden: !menuOpen })}>
          <div className="mt-2 top-full bg-white border border-gray-200 rounded-lg overflow-hidden shadow-lg z-50">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default NavItem;
