import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorPayload, Todo } from 'ui/types';
import { apiClient, getErrorMessage } from 'ui/utils';

export const fetchTodos = createAsyncThunk<Todo[], void, { rejectValue: ErrorPayload }>(
  'todos/fetchTodos',
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get<Todo[]>('/todos');
      return response.data;
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      return rejectWithValue({ message: errorMessage });
    }
  },
);

export default fetchTodos;
