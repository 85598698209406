import React, { useState, useEffect } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useStore } from 'ui/store/GenericContext';
import { ReactSelectOption, Todo as TodoType } from 'ui/types';
import { apiClient } from 'ui/utils';
import { formatOption, overrideControl, overrideThemes, overrideComponents } from 'ui/utils/reactSelect';
import edamamLogo from 'ui/assets/edamam.png';

interface Option {
  id: string;
  name: string;
  brand: string;
  sizes: string[];
}

interface State {
  item: Option | null;
  size: ReactSelectOption | null;
  servings: string;
  meal: ReactSelectOption | null;
}

const initialState: State = {
  item: null,
  size: null,
  servings: '',
  meal: null,
};

export const Nutrition: React.FC<{ todo: TodoType }> = ({ todo }) => {
  const { dispatch } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<State>(initialState);

  const search = debounce((input, callback) => {
    apiClient.get(`nutritions/search?query=${input}`)
      .then(res => res.data)
      .then(items => callback(items));
  }, 500);

  const formatLabel = (option: Option) => (
    <div>
      <div className="text-sm font-semibold line-clamp-2">{option.name}</div>
      <div className="text-xs text-muted line-clamp-1">{option.brand}</div>
    </div>
  );

  const submit = () => {
    if (!(data.item && data.size && data.servings && data.meal)) { return; }

    setSubmitting(true);

    const params = {
      food_id: data.item.id,
      name: data.item.name,
      measurement_type: data.size.value,
      servings: parseInt(data.servings, 10),
      meal: data.meal.value,
    };

    apiClient.post('nutritions', params)
      .then(() => {
        setData(initialState);
        setSuccess(true);
        dispatch({ type: 'SET_KEY', key: 'update', payload: todo.itemType });
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (error || success) {
      timeout = setTimeout(() => {
        setError(false);
        setSuccess(false);
        setSubmitting(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, success]);

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="w-full md:w-4/5 items-center flex flex-col md:flex-row justify-center gap-x-2 gap-y-4">
        <div className="form-group w-3/4 md:w-1/2">
          <label id="item-label" htmlFor="item">Item</label>

          <AsyncSelect
            name="item"
            id="item"
            aria-labelledby="item-label"
            value={data.item}
            className="form-control input-3"
            loadOptions={search}
            getOptionValue={(option: Option) => option.id}
            formatOptionLabel={(option: Option) => formatLabel(option)}
            onChange={(e: Option) => {
              if (e === null) { setData(initialState); }
              if (e !== null) { setData({ ...data, item: e }); }
            }}
            styles={{ control: base => (overrideControl(base)) }}
            theme={theme => overrideThemes(theme)}
            components={overrideComponents()}
            isClearable
            backspaceRemovesValue
          />
        </div>

        <div className="form-group w-3/4 md:w-1/3">
          <label id="size-label" htmlFor="size">Serving Size</label>

          <Select
            name="size"
            id="size"
            aria-labelledby="size-label"
            value={data.size}
            className="form-control input-3"
            isDisabled={!data.item}
            options={data.item?.sizes.map(formatOption)}
            onChange={(e: ReactSelectOption) => setData({ ...data, size: e })}
            styles={{ control: base => (overrideControl(base)) }}
            theme={theme => overrideThemes(theme)}
            components={overrideComponents()}
          />
        </div>

        <div className="form-group w-3/4 md:w-1/3">
          <label id="servings-label" htmlFor="servings">Servings</label>

          <input
            name="servings"
            id="servings"
            required
            type="number"
            min="0"
            max="1440"
            className="form-control !pt-[10px] !pb-[9px]"
            disabled={!data.size}
            placeholder="Servings"
            value={data.servings}
            onChange={e => setData({ ...data, servings: e.target.value })}
          />
        </div>

        <div className="form-group w-3/4 md:w-1/3">
          <label id="meal-label" htmlFor="meal">Meal</label>

          <Select
            name="meal"
            id="meal"
            aria-labelledby="meal-label"
            value={data.meal}
            className="form-control input-3"
            options={['Breakfast', 'Lunch', 'Dinner', 'Snack'].map(formatOption)}
            onChange={(e: ReactSelectOption) => setData({ ...data, meal: e })}
            styles={{ control: base => (overrideControl(base)) }}
            theme={theme => overrideThemes(theme)}
            components={overrideComponents()}
          />
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-y-2">
        <div className="w-full flex items-center justify-between">
          <div className="mr-auto w-40 invisible" />
          <button
            type="button"
            className={classNames('btn btn-primary w-3/5 md:w-1/5', { 'btn-disabled': submitting })}
            disabled={submitting || !(data.item && data.size && data.servings && data.meal)}
            onClick={submit}
          >
            Submit
          </button>

          <div className="w-40 ml-auto">
            <img src={edamamLogo} alt="Edamam Logo" />
          </div>
        </div>

        {error && (
          <span className="text-danger-default text-accent">
            There was an error submitting your activity. Please try again later.
          </span>
        )}

        {success && (
          <span className="text-success-default text-accent">
            Your activity has been recorded!
          </span>
        )}
      </div>
    </div>
  );
};

export default Nutrition;
