/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectClient } from 'ui/store/selectors';
import { Link } from 'ui/components/Common';
import { Menu } from './Menu';

export const Meta: React.FC = () => {
  const client = useSelector(selectClient);

  if (!client) { return null; }

  const handleLogin = () => {
    document.querySelector('#loginDropDown')?.classList.toggle('hidden');
  };

  return (
    <div className="md:w-[20%]">
      <div className="hidden md:flex gap-4 items-center ml-auto w-fit">
        {client.joinable && (
          <Link
            to="/users/sign_up"
            label="Join Now"
            className="btn border-white text-white text-center"
          >
            Join Now
          </Link>
        )}

        {client.loginable && (
          <button
            type="button"
            aria-label="Login"
            className="btn border-white text-white text-center"
            onClick={handleLogin}
          >
            Login
          </button>
        )}
      </div>

      <Menu />
    </div>
  );
};

export default Meta;
