/* eslint-disable react/no-array-index-key */
import React from 'react';

interface FooterColumnProps {
  text: string;
  children?: React.ReactNode;
}

export const FooterColumn: React.FC<FooterColumnProps> = ({
  text, children = undefined,
}) => (
  <div className="w-[25%]">
    <h3 className="mb-2 text-lg text-secondary font-semibold">{text}</h3>
    <div
      className="flex flex-col gap-1 font-light whitespace-nowrap"
    >
      {children}
    </div>
  </div>
);

export default FooterColumn;
