import React, { createContext, useContext, ReactNode, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { fetchClient } from '../store/actions/clientActions';

interface ClientContextType {
  client: RootState['client'];
  refreshClient: () => Promise<void>;
}

const ClientContext = createContext<ClientContextType | undefined>(undefined);

export const useClient = () => {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('useClient must be used within a ClientProvider');
  }
  return context;
};

export const ClientProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const client = useSelector((state: RootState) => state.client);

  const refreshClient = async () => {
    try {
      await dispatch(fetchClient()).unwrap();
    } catch (error) {
      console.error('Failed to refresh client:', error);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const value = useMemo(() => ({ client, refreshClient }), [client]);

  return <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;
};
