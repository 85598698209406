import React from 'react';
import moment from 'moment';
import { useStore } from 'ui/store/GenericContext';
import { apiClient } from 'ui/utils';
import { Todo as TodoType } from 'ui/types';
import { TodosType } from './types';

export const Todo: React.FC<{ todo: TodoType }> = ({ todo }) => {
  const { state, dispatch } = useStore<TodosType, any>();
  const { title, challengeTitle, cutoff } = todo;

  const fetchPath = () => {
    if (todo.type === 'PlanDesign') {
      return `plan_design/challenge_items/${todo.challengeItemId}`;
    }

    return `challenges/${todo.challengeId}/challenge_items/${todo.challengeItemId}`;
  };

  const openModal = () => {
    apiClient.get(fetchPath())
      .then(res => dispatch({ type: 'SET_STATE', payload: { ...state, modal: true, todo, item: res.data } }))
      .catch(() => {});
  };

  return (
    <div className="w-full self-center flex overflow-hidden flex-col p-4 bg-primary rounded-lg">
      <div className="mb-2 text-sm text-secondary truncate">{challengeTitle}</div>
      <div className="mb-2 text-lg text-secondary truncate">{title}</div>
      <div className="flex items-center justify-between w-full">
        <div className="grow text-secondary text-sm whitespace-nowrap">
          {/* eslint-disable-next-line newline-per-chained-call */}
          {moment(cutoff).endOf('day').fromNow().replace('in', '').concat(' left')}
        </div>

        <button
          type="button"
          className="btn btn-primary btn-sm text-sm text-secondary border-secondary"
          onClick={() => openModal()}
        >
          Complete
        </button>
      </div>
    </div>
  );
};

export default Todo;
