import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import Select from 'react-select';
import { useStore } from 'ui/store/GenericContext';
import { ReactSelectOption, Todo as TodoType } from 'ui/types';
import { apiClient } from 'ui/utils';
import { formatOption, overrideControl, overrideThemes, overrideComponents } from 'ui/utils/reactSelect';

interface State {
  start: { hour: ReactSelectOption, minute: ReactSelectOption, ampm: ReactSelectOption };
  end: { hour: ReactSelectOption, minute: ReactSelectOption, ampm: ReactSelectOption };
}

const initialState: State = {
  start: {
    hour: { label: '8', value: '8' },
    minute: { label: '00', value: '00' },
    ampm: { label: 'PM', value: 'PM' },
  },
  end: {
    hour: { label: '7', value: '7' },
    minute: { label: '00', value: '00' },
    ampm: { label: 'AM', value: 'AM' },
  },
};

export const Sleep: React.FC<{ todo: TodoType }> = ({ todo }) => {
  const hours = Array.from({ length: 12 }, (_, i) => String(i + 1));
  const minutes = Array.from({ length: 12 }, (_, i) => String(i * 5).padStart(2, '0'));
  const { dispatch } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<State>(initialState);

  const submit = () => {
    const params = {
      start_time_hour: data.start.hour.value,
      start_time_minute: data.start.minute.value,
      start_time_ampm: data.start.ampm.value,
      end_time_hour: data.end.hour.value,
      end_time_minute: data.end.minute.value,
      end_time_ampm: data.end.ampm.value,
    };

    setSubmitting(true);

    apiClient.post('sleeps', params)
      .then(() => {
        setData(initialState);
        setSuccess(true);
        dispatch({ type: 'SET_KEY', key: 'update', payload: todo.itemType });
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (error || success) {
      timeout = setTimeout(() => {
        setError(false);
        setSuccess(false);
        setSubmitting(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, success]);

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="w-full flex flex-col md:flex-row justify-center gap-x-12 gap-y-4">
        <div className="w-full md:w-2/5">
          <div className="inline-block text-form-label mb-1">
            What time did you go to bed?
          </div>

          <div className="flex items-center gap-2">
            <div className="form-group w-full">
              <label id="startHour-label" htmlFor="startHour" className="hidden">Start Hour</label>

              <Select
                name="startHour"
                id="startHour"
                aria-labelledby="startHour-label"
                value={data.start.hour}
                className="form-control input-3"
                options={hours.map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, start: { ...data.start, hour: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>

            <div className="form-group w-full">
              <label id="startMinute-label" htmlFor="startMinute" className="hidden">Start Minute</label>

              <Select
                name="startMinute"
                id="startMinute"
                aria-labelledby="startMinute-label"
                value={data.start.minute}
                className="form-control input-3"
                options={minutes.map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, start: { ...data.start, minute: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>

            <div className="form-group w-full">
              <label id="startAmPm-label" htmlFor="startAmPm" className="hidden">Start AM/PM</label>

              <Select
                name="startAmPm"
                id="startAmPm"
                aria-labelledby="startAmPm-label"
                value={data.start.ampm}
                className="form-control input-3"
                options={['AM', 'PM'].map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, start: { ...data.start, ampm: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>
          </div>
        </div>

        <div className="w-full md:w-2/5">
          <div className="inline-block text-form-label mb-1">
            What time did you wake up?
          </div>

          <div className="flex items-center gap-2">
            <label id="endHour-label" htmlFor="endHour" className="hidden">End Hour</label>

            <div className="form-group w-full">
              <Select
                name="endHour"
                id="endHour"
                aria-labelledby="endHour-label"
                value={data.end.hour}
                className="form-control input-3"
                options={hours.map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, end: { ...data.end, hour: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>

            <div className="form-group w-full">
              <label id="endMinute-label" htmlFor="endMinute" className="hidden">End Minute</label>

              <Select
                name="endMinute"
                id="endMinute"
                aria-labelledby="endMinute-label"
                value={data.end.minute}
                className="form-control input-3"
                options={minutes.map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, end: { ...data.end, minute: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>

            <div className="form-group w-full">
              <label id="endAmPm-label" htmlFor="endAmPm" className="hidden">End AM/PM</label>

              <Select
                name="endAmPm"
                id="endAmPm"
                aria-labelledby="endAmPm-label"
                value={data.end.ampm}
                className="form-control input-3"
                options={['AM', 'PM'].map(formatOption)}
                onChange={(e: ReactSelectOption) => setData({ ...data, end: { ...data.end, ampm: e } })}
                styles={{ control: base => (overrideControl(base)) }}
                theme={theme => overrideThemes(theme)}
                components={overrideComponents()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-y-2">
        <button
          type="button"
          className={classNames('btn btn-primary w-3/5 md:w-1/5', { 'btn-disabled': submitting })}
          disabled={submitting}
          onClick={submit}
        >
          Submit
        </button>

        {error && (
          <span className="text-danger-default text-accent">
            There was an error submitting your activity. Please try again later.
          </span>
        )}

        {success && (
          <span className="text-success-default text-accent">
            Your activity has been recorded!
          </span>
        )}
      </div>
    </div>
  );
};

export default Sleep;
