/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TodoState, Todo, ErrorPayload } from 'ui/types';
import { fetchTodos } from 'ui/store/actions/todosActions';

export const initialState: TodoState = {
  todos: [],
  status: 'idle',
  error: null,
};

const todosReducer = createSlice({
  name: 'todos',
  initialState,
  reducers: {
    replaceTodos(state, param) {
      const { payload } = param;
      state.todos = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTodos.fulfilled, (state, action: PayloadAction<Todo[]>) => {
        state.status = 'succeeded';
        state.todos = action.payload;
      })
      .addCase(fetchTodos.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message ?? 'Failed to fetch todos';
      });
  },
});

export const { replaceTodos } = todosReducer.actions;
export default todosReducer.reducer;
