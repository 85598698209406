/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Link } from 'ui/components/Common';
import { LinkVisitChallengeItem } from 'ui/types';

interface Props {
  item: LinkVisitChallengeItem;
}

export const LinkVisit: React.FC<Props> = ({ item }) => {
  if (item.recordingHidden) { return null; }

  return (
    <div className="mt-2 flex flex-col gap-3">
      <Link
        to={`/link_visits/${item.id}`}
        target="_blank"
        className="mt-2 mx-auto btn btn-primary"
      >
        Visit Link
      </Link>
    </div>
  );
};

export default LinkVisit;
