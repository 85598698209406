export type ReactSelectOption = {
  label: string;
  value: string;
};

export interface Address {
  street: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

export interface DocumentType {
  id: number;
  name: string;
  filename: string;
  description: string;
  filesize: string;
}

export enum ChallengeItemType {
  Biometric = 'BiometricChallengeItem',
  ChallengeUser = 'ChallengeUserChallengeItem',
  Coaching = 'CoachingEnrollmentChallengeItem',
  Combo = 'ComboChallengeItem',
  Entry = 'EntryChallengeItem',
  Fitness = 'FitnessChallengeItem',
  FormResponse = 'FormResponseChallengeItem',
  GeoCheckIn = 'GeoCheckInChallengeItem',
  LinkVisit = 'LinkVisitChallengeItem',
  NavigateYou = 'NavigateYouAssessmentChallengeItem',
  Nutrition = 'NutritionChallengeItem',
  Proxy = 'ProxyChallengeItem',
  ResourceView = 'ResourceViewChallengeItem',
  Routine = 'RoutineChallengeItem',
  Screening = 'ScreeningChallengeItem',
  Sleep = 'SleepChallengeItem',
  Weight = 'WeightChallengeItem',
}

export const DeviceableItemTypes = [
  ChallengeItemType.Fitness,
  ChallengeItemType.Nutrition,
  ChallengeItemType.Routine,
  ChallengeItemType.Sleep,
  ChallengeItemType.Weight,
];
