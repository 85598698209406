/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagState, ErrorPayload } from 'ui/types';
import { fetchTags } from 'ui/store/actions/postActions';

const initialState: TagState = {
  tags: [],
  status: 'idle',
  error: null,
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTags.fulfilled, (state, action: PayloadAction<string[]>) => {
        state.status = 'succeeded';
        state.tags = action.payload;
      })
      .addCase(fetchTags.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message || 'Failed to fetch tags';
      });
  },
});

export default tagsSlice.reducer;
