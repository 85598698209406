/* eslint-disable jsx-a11y/click-events-have-key-events */

import React from 'react';
import { Link } from 'ui/components/Common';
import { ResourceViewChallengeItem } from 'ui/types';

interface Props {
  item: ResourceViewChallengeItem;
}

export const ResourceView: React.FC<Props> = ({ item }) => {
  if (item.recordingHidden) { return null; }

  return (
    <div className="mt-2 flex flex-col gap-3">
      <Link
        to={`/posts/${item.post.split('/').at(-1)}`}
        className="mt-2 mx-auto btn btn-primary"
      >
        View Post
      </Link>
    </div>
  );
};

export default ResourceView;
