import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { pluralize, capitalize } from 'inflection';
import classNames from 'classnames';
import { selectUser, selectHubPosts, selectFeatures, selectVocabs } from 'ui/store/selectors';
import { CaratLeft, CaratRight, Times } from 'ui/components/Icons';
import { hasFeature, anyFeatures, allFeatures, getVocab, hasPermission } from 'ui/utils';
import { NavSubItem } from '../NavSubItem';
import useNavigation from '../useNavigation';

export const Menu: React.FC = () => {
  const navItems = useNavigation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [subMenu, setSubMenu] = useState<string | null>(null);
  const user = useSelector(selectUser);
  const features = useSelector(selectFeatures);
  const vocabs = useSelector(selectVocabs) || {};
  const posts = useSelector(selectHubPosts) || [];
  if (!user) { return null; }

  const renderDivider = () => (
    <hr className="shrink-0 border border-solid bg-stone-300 border-stone-300 mx-4" />
  );

  const goForward = (menu: string, label: string) => (
    <button
      type="button"
      className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-light-gray cursor-pointer"
      onClick={() => setSubMenu(menu)}
    >
      <div className="flex items-center justify-between">
        <span className="mr-3">{label}</span>
        <CaratRight width="w-[12px]" height="h-[12px]" />
      </div>
    </button>
  );

  const goBack = () => (
    <button
      type="button"
      className={classNames('block w-full text-left px-4 py-2 text-gray-700 hover:bg-light-gray cursor-pointer', { hidden: subMenu === null })}
      onClick={() => setSubMenu(null)}
    >
      <CaratLeft width="w-[12px]" height="h-[12px]" />
      <span className="ml-3">Go Back</span>
    </button>
  );

  const renderMenu = () => (
    <div data-testid="menu-wrapper" className={classNames('absolute w-48 bg-transparent right-0 z-50', { hidden: !menuOpen })}>
      <div className="wrapper mt-2 mb-0 p-0 border border-mid-gray overflow-hidden shadow-lg">
        <div data-testid="standard-menu" className={classNames({ hidden: subMenu !== null })}>
          <NavSubItem {...navItems.home} />

          {goForward('account', user.firstName)}

          {hasFeature('challenges', features) && anyFeatures(['challengeSearch', 'privateChallenges'], features) && (
            goForward('challenges', pluralize(capitalize(getVocab('challenge', vocabs))))
          )}

          {allFeatures(['challengeSearch', 'courses'], features) && (
            <NavSubItem {...navItems.challenges.options.courses} />
          )}

          {!!user.planDesign && (
            <NavSubItem {...navItems.planDesign} />
          )}

          {hasFeature('healthCoaching', features) && (
            <NavSubItem {...navItems.coaching} />
          )}

          {anyFeatures(['content', 'events', 'favorites', 'mealPlanner', 'navigateyou', 'photos', 'socialWall', 'tracker'], features) && (
            goForward('resources', 'Resources')
          )}

          {hasFeature('rewards', features) && (
            <NavSubItem {...navItems.rewards} />
          )}
        </div>

        <div data-testid="account-menu" className={classNames({ hidden: subMenu !== 'account' })}>
          <NavSubItem {...navItems.account.publicProfile} />

          {hasFeature('messaging', features) && (
            <NavSubItem {...navItems.account.messageCenter} />
          )}

          {renderDivider()}

          <NavSubItem {...navItems.account.accountSettings} />
          <NavSubItem {...navItems.account.privacySettings} />
          <NavSubItem {...navItems.account.notificationSettings} />

          {renderDivider()}

          {hasFeature('translation', features) && (
            <NavSubItem {...navItems.account.language} />
          )}

          <NavSubItem {...navItems.account.support} />

          {hasPermission('admin', user.access) && <NavSubItem {...navItems.account.admin} />}
          {hasPermission('coaching', user.access) && <NavSubItem {...navItems.account.coaching} />}

          {renderDivider()}

          <NavSubItem {...navItems.account.signout} />
        </div>

        <div data-testid="challenges-menu" className={classNames({ hidden: subMenu !== 'challenges' })}>
          {hasFeature('challengeSearch', features) && (
            <NavSubItem {...navItems.challenges.options.search} />
          )}

          {hasFeature('privateChallenges', features) && (
            <NavSubItem {...navItems.challenges.options.create} />
          )}
        </div>

        <div data-testid="resources-menu" className={classNames({ hidden: subMenu !== 'resources' })}>
          {hasFeature('content', features) && (
            <React.Fragment>
              {posts.length > 0 && (
                <NavSubItem {...navItems.resources.options.hubPosts} />
              )}

              <NavSubItem {...navItems.resources.options.articles} />
              <NavSubItem {...navItems.resources.options.recipes} />
              <NavSubItem {...navItems.resources.options.workouts} />
            </React.Fragment>
          )}

          {hasFeature('favorites', features) && (
            <NavSubItem {...navItems.resources.options.favorites} />
          )}

          {hasFeature('mealPlanner', features) && (
            <NavSubItem {...navItems.resources.options.mealPlans} />
          )}

          {hasFeature('events', features) && (
            <React.Fragment>
              {renderDivider()}

              <NavSubItem {...navItems.events.options.index} />
              <NavSubItem {...navItems.events.options.enrolled} />
              <NavSubItem {...navItems.events.options.past} />
            </React.Fragment>
          )}

          {anyFeatures(['navigateyou', 'photos', 'socialWall', 'tracker'], features) && (
            <React.Fragment>
              {renderDivider()}

              {hasFeature('navigateyou', features) && (
                <NavSubItem {...navItems.navigateYou} />
              )}

              {hasFeature('photos', features) && (
                <NavSubItem {...navItems.photos} />
              )}

              {hasFeature('socialWall', features) && (
                <NavSubItem {...navItems.socialWall} />
              )}

              {hasFeature('tracker', features) && (
                <NavSubItem {...navItems.tracker} />
              )}
            </React.Fragment>
          )}
        </div>

        {goBack()}
      </div>
    </div>
  );

  return (
    <div
      className="relative md:hidden"
      role="menu"
      aria-label="Main Menu"
      tabIndex={0}
    >
      <button
        type="button"
        className="btn btn-primary w-[80px] text-center"
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyDown={e => e.key === 'Enter' && setMenuOpen(!menuOpen)}
      >
        <div className={classNames({ hidden: menuOpen })}>Menu</div>
        <div className={classNames({ hidden: !menuOpen })}><Times width="w-[12px]" height="h-[12px]" stroke="stroke-white" /></div>
      </button>

      {renderMenu()}
    </div>
  );
};

export default Menu;
