import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectSplash } from 'ui/store/selectors';
import { Times } from 'ui/components/Icons';
import { NavSubItem } from '../NavSubItem';
import useNavigation from '../useNavigation';

export const Menu: React.FC = () => {
  const navItems = useNavigation();
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const splash = useSelector(selectSplash);

  if (!splash) { return null; }

  const renderMenu = () => (
    <div data-testid="menu-wrapper" className={classNames('absolute w-48 bg-transparent right-0 z-50', { hidden: !menuOpen })}>
      <div className="wrapper mt-2 mb-0 p-0 border border-mid-gray overflow-hidden shadow-lg">
        <NavSubItem {...navItems.splash.overview} />

        {splash.posts.length > 0 && (
          <NavSubItem {...navItems.splash.about} />
        )}

        {splash.sponsorContent && (
          <NavSubItem {...navItems.splash.sponsors} />
        )}

        <NavSubItem {...navItems.splash.contact} />
      </div>
    </div>
  );

  return (
    <div
      className="relative md:hidden"
      role="menu"
      aria-label="Splash Menu"
      tabIndex={0}
    >
      <button
        type="button"
        className="btn btn-primary w-[80px] text-center"
        onClick={() => setMenuOpen(!menuOpen)}
        onKeyDown={e => e.key === 'Enter' && setMenuOpen(!menuOpen)}
      >
        <div className={classNames({ hidden: menuOpen })}>Menu</div>
        <div className={classNames({ hidden: !menuOpen })}><Times width="w-[12px]" height="h-[12px]" stroke="stroke-white" /></div>
      </button>

      {renderMenu()}
    </div>
  );
};

export default Menu;
