import { ChallengeItemType as ItemType } from 'ui/types/commonTypes';

export enum ItemStatusCode {
  Pending = 'pending',
  Complete = 'complete',
  Failed = 'failed',
  NotComplete = 'not_complete',
  Locked = 'locked',
  Denied = 'denied',
  InProgress = 'in_progress',
  Waived = 'waived',
  NotYetOpen = 'not_yet_open',
  RecordingClosed = 'recording_closed',
}

export type ItemStatus = {
  code: ItemStatusCode;
  planDesignStatus: string;
  progress: number;
  attempts?: number;
  maxAttempts?: number;
  activitySum?: number;
};

export type BaseChallengeItem = {
  id: number;
  type: ItemType;
  name: string;
  longName: string;
  category: string;
  challengeItemGroupId: number | null;
  dashboard: boolean;
  description: string;
  sanitizedDescription: string;
  image: string;
  startDate: string | null;
  endDate: string | null;
  datesStatus: 'current' | 'future' | 'past';
  pointsEarned: number;
  pointsPossible: number;
  pointsFraction: boolean;
  quantity: number;
  completionLimit: number;
  simpleRecording: boolean;
  unit: string;
  unitType: string;
  status: ItemStatus;
  todo?: { [key: string]: unknown, completed: boolean };
  parents?: { [key: string]: unknown };
  waivable?: boolean;
  waiver?: { formId: number, status: { id: number, status: string } };
};

export type ComboState = {
  style: string;
  combo: number;
  setCombo: React.Dispatch<React.SetStateAction<number>>
};

export type BiometricChallengeItem = BaseChallengeItem & {
  type: ItemType.Biometric;
  formId?: number;
  biometricDetails: {
    url: string, currentBiometrics: boolean, pastBiometrics: boolean,
    formId: number | null, responses: Array<any>, conditionGroups?: Array<any>,
  };
};

export type ChallengeItemChallenge = {
  id: number;
  name: string;
  link: string;
  value: number;
  registerStartDate: string;
  registerEndDate: string;
};

export type ChallengeUserChallengeItem = BaseChallengeItem & {
  type: ItemType.ChallengeUser;
  challengeUserChallengeItemDetails: {
    qualifiedScopes: string,
    challenges: ChallengeItemChallenge[],
  };
};

export type CoachingEnrollmentChallengeItem = BaseChallengeItem & {
  type: ItemType.Coaching;
  coachingPrograms: { id: number, name: string, status: string }[];
};

export type ComboChallengeItem = BaseChallengeItem & {
  type: ItemType.Combo;
  primaryItems: PlanDesignChallengeItem[];
  alternateItems?: PlanDesignChallengeItem[];
};

export type FormResponseChallengeItem = BaseChallengeItem & {
  type: ItemType.FormResponse;
  formId: number;
  formResponseDetails: { formId: number, quiz: boolean, responses: Array<any>, };
};

export type LinkVisitChallengeItem = BaseChallengeItem & {
  type: ItemType.LinkVisit;
  link: string;
  recordingHidden: boolean;
};

export type ProxyChallengeItem = BaseChallengeItem & {
  type: ItemType.Proxy;
  formId: number;
};

export type ResourceViewChallengeItem = BaseChallengeItem & {
  type: ItemType.ResourceView;
  post: string;
  recordingHidden: boolean;
};

export type ScreeningChallengeItem = BaseChallengeItem & {
  type: ItemType.Screening;
  screeningDetails: {
    screeningUploadEnabled: boolean;
    screenings: { id: number, kind: string, activityDate: string, attachment?: string }[];
  };
};

export type PlanDesignChallengeItem =
 | BaseChallengeItem
 | BiometricChallengeItem
 | ChallengeUserChallengeItem
 | CoachingEnrollmentChallengeItem
 | ComboChallengeItem
 | FormResponseChallengeItem
 | LinkVisitChallengeItem
 | ProxyChallengeItem
 | ResourceViewChallengeItem
 | ScreeningChallengeItem;

export type ChallengeItemGroup = {
  id: number | null;
  position: number;
  name: string;
  description: string;
};

export type PlanDesign = {
  id: number;
  dashboard: boolean;
  name: string;
  description: string;
  sanitizedDescription: string;
  summary: { completed: number, total: number, description: string };
  settings: { buttonText: string, secondaryDescription: string, style: 'rows' | 'cards' };
  challengeItemGroups: ChallengeItemGroup[];
  challengeItems: PlanDesignChallengeItem[];
};
