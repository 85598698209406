import { RootState } from '..';

export const selectClient = (state: RootState) => state.client.client;
export const selectClientName = (state: RootState) => state.client.client?.name;
export const selectFeatures = (state: RootState) => state.client.client?.featureSettings;
export const selectVocabs = (state: RootState) => state.client.client?.vocab;
export const selectTheme = (state: RootState) => state.client.client?.theme;
export const selectSettings = (state: RootState) => state.client.client?.clientSettings;
export const selectSplash = (state: RootState) => state.client.client?.splash;
export const selectFeaturedChallenge = (state: RootState) => state.client.client?.featuredChallenge;
export const selectHubPosts = (state: RootState) => state.client.client?.hubPosts;
export const selectSidebarContents = (state: RootState) => state.client.client?.sidebarContents;
export const selectDashboardConfig = (state: RootState) => state.dashboard.config;
export const selectDashboardData = (state: RootState) => state.dashboard.data;
