/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardState, Dashboard, ErrorPayload } from 'ui/types';
import { fetchDashboard } from 'ui/store/actions/clientActions';

export const initialState: DashboardState = {
  config: null,
  data: {},
  status: 'idle',
  error: null,
};

const dashboardReducer = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateData: (state, action: PayloadAction<object>) => {
      state.data = { ...state.data, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDashboard.fulfilled, (state, action: PayloadAction<Dashboard>) => {
        state.status = 'succeeded';
        state.config = action.payload;
      })
      .addCase(fetchDashboard.rejected, (state, action: PayloadAction<ErrorPayload | undefined>) => {
        state.status = 'failed';
        state.error = action.payload?.message ?? 'Unknown error';
      });
  },
});

export const { updateData } = dashboardReducer.actions;
export default dashboardReducer.reducer;
