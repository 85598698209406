import { ChallengeItem, ChallengeItemType, PlanDesignChallengeItem, Todo } from 'ui/types';

export type TodosType = {
  modal: boolean;
  todo: Todo;
  item: ChallengeItem | PlanDesignChallengeItem;
  update: ChallengeItemType | null;
};

export const todosState = {
  modal: false,
  todo: null,
  item: null,
  update: null,
};
