/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { useSelector } from 'react-redux';
import { selectFeatures, selectHubPosts, selectUserPlanDesign, selectVocabs } from 'ui/store/selectors';
import { hasFeature, anyFeatures, allFeatures } from 'ui/utils';
import { NavItem } from '../NavItem';
import { NavSubItem } from '../NavSubItem';
import useNavigation from '../useNavigation';

interface HeaderProps {
  layout: string;
}

export const Bar: React.FC<HeaderProps> = ({ layout }) => {
  const vocabs = useSelector(selectVocabs) || {};
  const features = useSelector(selectFeatures);
  const planDesign = useSelector(selectUserPlanDesign);
  const posts = useSelector(selectHubPosts) || [];
  const render = features && Object.keys(features).length > 0 && Object.keys(vocabs).length > 0;
  const navItems = useNavigation();

  if (!render) { return null; }

  const renderDivider = () => (
    <hr className="shrink-0 border border-solid bg-stone-300 border-stone-300 mx-4" />
  );

  return (
    <div className="mx-auto my-2 hidden md:block" data-testid="standard-nav-bar">
      <nav className="flex gap-4 lg:gap-8 items-center">
        <NavItem layout={layout} item={navItems.home} />

        {hasFeature('challenges', features) && anyFeatures(['challengeSearch', 'privateChallenges'], features) && (
          <NavItem layout={layout} name="challenges" item={navItems.challenges}>
            {hasFeature('challengeSearch', features) && <NavSubItem {...navItems.challenges.options.search} />}
            {hasFeature('privateChallenges', features) && <NavSubItem {...navItems.challenges.options.create} />}
          </NavItem>
        )}

        {allFeatures(['challengeSearch', 'courses'], features) && (
          <NavItem layout={layout} item={navItems.challenges.options.courses} />
        )}

        {!!planDesign && <NavItem layout={layout} item={navItems.planDesign} />}

        {hasFeature('healthCoaching', features) && (
          <NavItem layout={layout} item={navItems.coaching} />
        )}

        {anyFeatures(['content', 'events', 'favorites', 'mealPlanner', 'navigateyou', 'photos', 'socialWall', 'tracker'], features) && (
          <NavItem layout={layout} name="resources" item={navItems.resources}>
            {hasFeature('content', features) && (
              <React.Fragment>
                {posts.length > 0 && (
                  <NavSubItem {...navItems.resources.options.hubPosts} />
                )}

                <NavSubItem {...navItems.resources.options.articles} />
                <NavSubItem {...navItems.resources.options.recipes} />
                <NavSubItem {...navItems.resources.options.workouts} />
              </React.Fragment>
            )}

            {hasFeature('favorites', features) && (
              <NavSubItem {...navItems.resources.options.favorites} />
            )}

            {hasFeature('mealPlanner', features) && (
              <NavSubItem {...navItems.resources.options.mealPlans} />
            )}

            {hasFeature('events', features) && (
              <React.Fragment>
                {renderDivider()}

                <NavSubItem {...navItems.events.options.index} />
                <NavSubItem {...navItems.events.options.enrolled} />
                <NavSubItem {...navItems.events.options.past} />
              </React.Fragment>
            )}

            {anyFeatures(['navigateyou', 'photos', 'socialWall', 'tracker'], features) && (
              <React.Fragment>
                {renderDivider()}

                {hasFeature('navigateyou', features) && (
                  <NavSubItem {...navItems.navigateYou} />
                )}

                {hasFeature('photos', features) && (
                  <NavSubItem {...navItems.photos} />
                )}

                {hasFeature('socialWall', features) && (
                  <NavSubItem {...navItems.socialWall} />
                )}

                {hasFeature('tracker', features) && (
                  <NavSubItem {...navItems.tracker} />
                )}
              </React.Fragment>
            )}
          </NavItem>
        )}

        {hasFeature('rewards', features) && (
          <NavItem layout={layout} item={navItems.rewards} />
        )}
      </nav>
    </div>
  );
};

export default Bar;
