import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { useStore } from 'ui/store/GenericContext';
import { Todo as TodoType } from 'ui/types';
import { apiClient } from 'ui/utils';

export const Steps: React.FC<{ todo: TodoType }> = ({ todo }) => {
  const { dispatch } = useStore();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [data, setData] = useState<string>('');

  const submit = () => {
    setSubmitting(true);

    apiClient.post('routines', { steps: data })
      .then(() => {
        setData('');
        setSuccess(true);
        dispatch({ type: 'SET_KEY', key: 'update', payload: todo.itemType });
      })
      .catch(() => setError(true));
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (error || success) {
      timeout = setTimeout(() => {
        setError(false);
        setSuccess(false);
        setSubmitting(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [error, success]);

  return (
    <div className="flex flex-col items-center gap-y-6">
      <div className="w-full flex flex-col md:flex-row justify-center gap-x-12 gap-y-4">
        <div className="m-auto w-full md:w-1/3">
          <div className="form-group">
            <label className="text-form-label" htmlFor="steps">Steps</label>

            <input
              name="steps"
              id="steps"
              required
              type="number"
              min="0"
              max="200000"
              className="form-control"
              placeholder="Example: 155"
              value={data}
              onChange={e => setData(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col items-center gap-y-2">
        <button
          type="button"
          className={classNames('btn btn-primary w-3/5 md:w-1/5', { 'btn-disabled': submitting })}
          disabled={submitting || !data}
          onClick={submit}
        >
          Submit
        </button>

        {error && (
          <span className="text-danger-default text-accent">
            There was an error submitting your activity. Please try again later.
          </span>
        )}

        {success && (
          <span className="text-success-default text-accent">
            Your activity has been recorded!
          </span>
        )}
      </div>
    </div>
  );
};

export default Steps;
